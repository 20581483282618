.mt35 {
  margin-top: 35px;
}

.p {
  margin-top: 0;
  margin-bottom: 10px;
}

.p {
  color: var(--paragraph-gray);
  text-align: left;
  letter-spacing: .01em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
}

.layoutFlex {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.valueRightTitleContentInner {
  z-index: 10;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.valuePageServices {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  align-items: stretch;
  width: 100%;
}

.valueSectionGradientBlock {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  background-image: linear-gradient(357deg, #57136f, #1e0d3a);
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 440px;
  padding: 40px 40px;
  display: flex;
  position: sticky;
  top: 0;
  overflow: hidden;
  height: 100%;
}

.valuesSectionRightList {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  //width: 50%;
  display: grid;
}

.valueRightSectionInnerGradient {
  filter: blur(80px);
  background-color: #f265f2;
  border-radius: 300px;
  width: 300px;
  height: 300px;
  position: absolute;
  top: auto;
  bottom: -32%;
  left: 50%;
  right: 0%;
  transform: translate(-50%);
}

.white {
  color: var(--white);
}

@media screen and (min-width: 1280px) {
  .valuePageServices {
    align-items: stretch;
  }

  .valueSectionGradientBlock {
    position: relative;
    min-width: 500px;
  }
}

@media screen and (min-width: 1920px) {
  .valuesSectionRightList {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

@media screen and (max-width: 991px) {
  .valuePageServices {
    flex-direction: column;
  }

  .valueSectionGradientBlock {
    max-width: 100%;
    position: relative;
  }

  .valuesSectionRightList {
    width: 100%;
  }

  .valueRightSectionInnerGradient {
    width: 400px;
  }
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 479px) {
  .valueSectionGradientBlock {
    padding: 60px 20px;
  }

  .valuesSectionRightList {
    grid-template-columns: 1fr;
  }
}