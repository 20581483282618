.mt40 {
  margin-top: 40px;
}

.mb40 {
  margin-bottom: 40px;
}

.pricingPlansWrapper {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
}

@media screen and (max-width: 991px) {
  .pricingPlansWrapper {
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .pricingPlansWrapper {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}