.wTabs {
  position: relative;
  width: 100%;
}

.wTabs:before, .wTabs:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.wTabs:after {
  clear: both;
}

.mt40 {
  margin-top: 40px;
}