.noMB {
  margin-bottom: 0;
}

.mtAuto {
  margin-top: auto;
}

.mt40 {
  margin-top: 40px;
}

.pricingPlanItem {
  border: 1px solid var(--paragraph-light);
  background-color: var(--white);
  border-radius: 10px;
  padding: 40px 30px;
  transition: all .3s;
}

.pricingPlanItem:hover {
  transform: translate(0, -10px);
  box-shadow: 0 2px 20px rgba(0, 0, 0, .1);
}

.pricingPlanList {
  grid-row-gap: 15px;
  margin-top: 40px;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.pricingPlanListItem {
  background-image: url('check_bold.svg');
  background-position: 0;
  background-repeat: no-repeat;
  background-size: auto;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 35px;
  font-size: 18px;
  font-weight: 500;
}

.pricingPlanItemTitle {
  border-bottom: 1px solid var(--paragraph-light);
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.pricingPlanPriceWrapper {
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}

.pricingPlanPrice {
  font-size: 46px;
}

.pricingPlanCurrencyIcon {
  margin-right: 2px;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}

@media screen and (min-width: 1280px) {
  .pricingPlanItem {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 991px) {
  .pricingPlanItem {
    max-width: 360px;
  }
}

@media screen and (max-width: 767px) {
  .pricingPlanItem {
    width: 80%;
    max-width: none;
  }
}

@media screen and (max-width: 479px) {
  .pricingPlanItem {
    width: 100%;
    padding: 30px 20px;
  }
}