.fullWidth {
  width: 100%;
}

.wButton {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #3898ec;
  border: 0;
  border-radius: 0;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

.primaryButton {
  border: 1px solid var(--primary-violet);
  background-color: var(--primary-violet);
  color: var(--light-gray);
  text-align: center;
  letter-spacing: .5px;
  text-transform: none;
  white-space: nowrap;
  border-radius: 50px;
  padding: 18px 38px;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1em;
  transition: all .3s;
  display: inline-block;

  &:hover {
    background-color: var(--transparent);
    opacity: 1;
    color: var(--primary-violet);
  }
}

.secondaryButton {
  border: 1px solid var(--primary-reach);
  background-color: var(--primary-reach);

  &:hover {
    color: var(--primary-reach);
    background-color: rgba(0, 0, 0, 0);
  }
}

input[disabled].primaryButton {
  cursor: not-allowed;
  background-color: #eee;
  color: var(--primary-violet);
  border: 1px solid var(--light-gray);
}