.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section.pricingServices {
  background-color: #481260;
  padding-top: 0;
  padding-bottom: 80px;
  position: relative;
}

.servicesWrapper {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  position: relative;
}

@media screen and (min-width: 1280px) {
  .section {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .section.pricingServices {
    padding-bottom: 130px;
  }

  .servicesWrapper {
    width: 96%;
  }
}

@media screen and (min-width: 1440px) {
  .servicesWrapper {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
}

@media screen and (min-width: 1920px) {
  .servicesWrapper {
    width: 80%;
  }
}

@media screen and (max-width: 991px) {
  .section {
    background-color: #fff;
  }

  .servicesWrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .section {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .servicesWrapper {
    column-count: 1;
    flex-wrap: wrap;
    grid-template-columns: 1fr;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 479px) {
  .section {
    background-color: #fff;
  }

  .servicesWrapper {
    grid-template-columns: 1fr;
    padding-left: 15px;
    padding-right: 15px;
  }
}