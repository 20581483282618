.tabMenuItem {
  border: 1px solid var(--paragraph-light);
  background-color: var(--transparent);
  border-radius: 24px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 700;
  max-width: 100%;
  display: inline-block;
}

.tabMenuItem.current {
  background-color: #f3ecf6;
  font-weight: 700;
}

.tabMenu {
  position: relative;
  justify-content: space-between;
  max-width: 1060px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.tabMenu.mb40 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-bottom: 40px;
  display: flex;
}

.tabLink {
  vertical-align: top;
  text-align: center;
  cursor: pointer;
  color: #222;
  background-color: #ddd;
  padding: 9px 30px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.tabLink.w--current {
  background-color: #c8c8c8;
}

.tabLink:focus {
  outline: 0;
}

.a {
  background-color: rgba(0, 0, 0, 0);
  color: var(--primary-reach);
  text-align: center;
  align-items: center;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2em;
  text-decoration: none;
  transition: all .35s;
}

.a:active, .a:hover {
  outline: 0;
}

.a:hover {
  opacity: 1;
  color: var(--primary-violet);
}

.listDetailedItems {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

@media screen and (max-width: 991px) {
  .tabMenu.mb40 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .listDetailedItems {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479px) {
  .tabLink {
    display: block;
  }
}