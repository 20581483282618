.statisticItem {
  flex-direction: column;
  display: flex;
}

.statisticItem.horizontal {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border: 1px solid var(--paragraph-light);
  background-color: var(--light-gray);
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  padding: 20px;
}

.noOverflow {
  overflow-wrap: normal;
}